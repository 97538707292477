import mount from '@lib/mount.js';
import { beforeLoad, afterLoad } from '@bootstrap/desktop/default';

const loadApp = () => {
  return import(
    /* webpackChunkName: "desktop-header" */
    '@apps/desktop/header.js'
  ).then((app) => {
    mount(app, '#footer');
  });
};

beforeLoad().then(loadApp).then(afterLoad);
